.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 90%;
  max-width: 800px;
  background: white;
  z-index: 1001;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
  animation: slideIn 0.3s ease-out;
  overflow-y: auto;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.closeButton {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
  z-index: 2;
}

.tabs {
  display: flex;
  border-bottom: 1px solid #e6e6e6;
  background: white;
  position: sticky;
  top: 0;
  z-index: 1;
}

.tab {
  padding: 15px 20px;
  border: none;
  background: none;
  cursor: pointer;
  color: #666;
  font-size: 14px;
  position: relative;
}

.tab.active {
  color: #4682B4;
  font-weight: 600;
}

.tab.active::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  height: 2px;
  background: #4682B4;
}

.content {
  padding: 20px;
}

.itinerarySegment {
  margin-bottom: 30px;
}

.itineraryHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.itineraryHeader h3 {
  margin: 0;
  color: #333;
}

.totalDuration {
  color: #666;
  font-size: 14px;
}

.segment {
  background: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}

.airline {
  margin-bottom: 15px;
}

.airlineInfo {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.operatedBy {
  color: #666;
  font-size: 12px;
}

.flightTimes {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 20px;
  align-items: center;
}

.departure, .arrival {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.arrival {
  text-align: right;
}

.time {
  font-size: 24px;
  font-weight: 600;
  color: #333;
}

.airport {
  font-size: 14px;
  color: #666;
}

.flightDuration {
  text-align: center;
  position: relative;
  padding: 0 20px;
}

.durationLine {
  position: relative;
  height: 2px;
  background: #e6e6e6;
  margin: 10px 0;
}

.durationLine::before,
.durationLine::after {
  content: '•';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #4682B4;
}

.durationLine::before {
  left: -5px;
}

.durationLine::after {
  right: -5px;
}

.duration {
  font-size: 12px;
  color: #666;
  background: #f8f9fa;
  padding: 0 10px;
}

.layover {
  margin-top: 15px;
  padding: 10px;
  background: #E6F0FF;
  border-radius: 4px;
  color: #4682B4;
  font-size: 14px;
  text-align: center;
}

.fareRules, .baggage {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.ruleSection, .baggageSection {
  padding: 20px;
  background: #f8f9fa;
  border-radius: 8px;
}

.ruleSection h4, .baggageSection h4 {
  margin: 0 0 10px 0;
  color: #333;
}

.fareBreakup {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.fareItem {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  border-bottom: 1px solid #e6e6e6;
}

.fareItem.total {
  border-top: 2px solid #e6e6e6;
  border-bottom: none;
  font-weight: 600;
  font-size: 18px;
  margin-top: 10px;
  padding-top: 20px;
}

@media (max-width: 768px) {
  .modal {
    width: 100%;
  }
  
  .tabs {
    overflow-x: auto;
    white-space: nowrap;
  }
  
  .flightTimes {
    grid-template-columns: 1fr;
    text-align: center;
  }
  
  .arrival {
    text-align: center;
  }
}
