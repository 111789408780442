.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  z-index: 1000;
  margin-top: 0.5rem;
}

.classSelection {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.classOption {
  padding: 0.75rem;
  border: 1px solid #E6F0FF;
  border-radius: 4px;
  background: white;
  color: #333;
  cursor: pointer;
  transition: all 0.3s ease;
}

.classOption:hover {
  border-color: #4682B4;
}

.classOption.active {
  background: #4682B4;
  color: white;
  border-color: #4682B4;
}

.passengerSection {
  border-top: 1px solid #E6F0FF;
  padding-top: 1rem;
}

.passengerSection h3 {
  color: #333;
  margin-bottom: 1rem;
  font-size: 1rem;
}

.passengerType {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.passengerInfo {
  display: flex;
  flex-direction: column;
}

.ageInfo {
  font-size: 0.8rem;
  color: #666;
}

.counter {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.counter button {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: none;
  background: #4682B4;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.counter button:disabled {
  background: #E6F0FF;
  color: #666;
  cursor: not-allowed;
}

.counter button:hover:not(:disabled) {
  background: #003D99;
}

.actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
  border-top: 1px solid #E6F0FF;
  padding-top: 1rem;
}

.resetBtn, .applyBtn {
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
}

.resetBtn {
  background: #E6F0FF;
  color: #4682B4;
}

.applyBtn {
  background: #4682B4;
  color: white;
}

.resetBtn:hover {
  background: #4682B4;
  color: white;
}

.applyBtn:hover {
  background: #003D99;
}
