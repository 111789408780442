.resultsContainer {
    padding: 20px;
    background-color: #F5F5F5;
}

.resultsHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.routeInfo h2 {
    margin: 0;
    font-size: 1.5em;
    color: #333333;
}

.editSearch {
    padding: 8px 16px;
    background: #FFFFFF;
    border: 1px solid #4682B4;
    border-radius: 4px;
    cursor: pointer;
    color: #4682B4;
    transition: all 0.3s ease;
}

.editSearch:hover {
    background: #4682B4;
    color: #FFFFFF;
}

.filterBar {
    background: #FFFFFF;
    padding: 15px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.filterGroup {
    display: flex;
    gap: 10px;
}

.filterGroup button {
    padding: 8px 16px;
    background: transparent;
    border: 1px solid #4682B4;
    border-radius: 4px;
    cursor: pointer;
    color: #4682B4;
    transition: all 0.3s ease;
}

.filterGroup button:hover {
    background: #E6F0FF;
}

.filterActions {
    display: flex;
    gap: 10px;
}

.moreFilters {
    background: transparent;
    border: 1px solid #4682B4;
    color: #4682B4;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.moreFilters:hover {
    background: #E6F0FF;
}

.reset {
    background: #4682B4;
    color: #FFFFFF;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.reset:hover {
    background: #003D99;
}

.quickResults {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FFFFFF;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.resultCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 20px;
    border-right: 1px solid #E6F0FF;
}

.resultCard:last-child {
    border-right: none;
}

.resultCard span {
    color: #666666;
}

.resultCard strong {
    color: #4682B4;
    font-size: 1.2em;
}

.sortBy {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #333333;
}

.sortBy select {
    padding: 8px;
    border: 1px solid #4682B4;
    border-radius: 4px;
    color: #333333;
    background: #FFFFFF;
}

.flightCard {
    background: #FFFFFF;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 15px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transition: transform 0.3s ease;
    position: relative;
}

.flightCard:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.flightInfo {
    display: grid;
    grid-template-columns: 2fr 5fr 2fr;
    gap: 20px;
    align-items: center;
}

.airline {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.flightNumber {
    color: #666666;
    font-size: 0.9em;
}

.operatedBy {
    font-size: 0.8em;
    color: #666666;
}

.timeInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 0 20px;
}

.departure, .arrival {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.departure strong, .arrival strong {
    font-size: 1.5em;
    color: #333333;
    margin-bottom: 4px;
}

.departure span, .arrival span {
    color: #666666;
    font-size: 0.9em;
}

.flightPath {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    padding: 0 20px;
}

.pathLine {
    width: 100%;
    height: 2px;
    background: #E6F0FF;
    position: relative;
    margin: 10px 0;
}

.pathLine::after {
    content: '→';
    position: absolute;
    right: -5px;
    top: 50%;
    transform: translateY(-50%);
    color: #4682B4;
}

.duration {
    font-size: 0.9em;
    color: #666666;
    margin-bottom: 4px;
}

.stops {
    font-size: 0.9em;
    color: #4682B4;
}

.fareInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
}

.price {
    text-align: right;
}

.price strong {
    display: block;
    font-size: 1.5em;
    color: #333333;
    margin-bottom: 4px;
}

.refundable {
    display: block;
    font-size: 0.8em;
    color: #666666;
}

.selectFlight {
    background: #4682B4;
    color: #FFFFFF;
    border: none;
    padding: 8px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: 500;
    min-width: 120px;
}

.selectFlight:hover {
    background: #003D99;
}

.flightDetails {
    background: transparent;
    border: 1px solid #4682B4;
    color: #4682B4;
    padding: 8px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: 500;
    min-width: 120px;
}

.flightDetails:hover {
    background: #E6F0FF;
}

.stopInfo {
    position: absolute;
    top: -8px;
    left: 50%;
    transform: translateX(-50%);
    background: #FFFFFF;
    padding: 2px 8px;
    border-radius: 4px;
    font-size: 0.8em;
    color: #4682B4;
    white-space: nowrap;
}

@media (max-width: 768px) {
    .flightInfo {
        grid-template-columns: 1fr;
        gap: 15px;
    }

    .timeInfo {
        padding: 0;
    }

    .fareInfo {
        align-items: center;
    }

    .price {
        text-align: center;
    }
}

.returnLabel {
    margin: 15px 0;
    padding: 10px;
    background: #E6F0FF;
    color: #4682B4;
    border-radius: 4px;
    font-weight: 500;
    text-align: center;
}

.footer {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    color: #666666;
    font-size: 0.9em;
}

.terms {
    cursor: pointer;
    color: #4682B4;
}

.terms:hover {
    color: #003D99;
}

.loadingContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px;
    background: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin: 20px 0;
}

.loadingSpinner {
    width: 50px;
    height: 50px;
    border: 3px solid #E6F0FF;
    border-radius: 50%;
    border-top-color: #4682B4;
    animation: spin 1s linear infinite;
    margin-bottom: 20px;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.loadingContainer p {
    color: #4682B4;
    font-size: 1.1em;
    margin: 0;
}

.errorMessage {
    padding: 20px;
    background: #FFE6E6;
    color: #FF0000;
    border-radius: 8px;
    margin: 20px 0;
    text-align: center;
    border: 1px solid #FF0000;
}

.noResults {
    padding: 40px;
    background: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin: 20px 0;
    text-align: center;
}

.noResults h2 {
    color: #333333;
    margin-bottom: 10px;
}

.noResults p {
    color: #666666;
    margin: 0;
}
